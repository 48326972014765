import { OpenAPIV3 } from "openapi-types"
import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"

import { ApiEvent } from "../../../../plugins/gatsby-source-openapi3/api-parser"
import { OpenApiUtils } from "../../../../plugins/gatsby-source-openapi3/openapi-utils"
import { EVENTS_PAGE_PATH } from "../../../hooks/content-sections"
import { useFocusedEvent } from "../../../hooks/focused-event"
import { CodeSampleBlock } from "../../common/code-sample-block"
import { HeadingFour, HeadingThree } from "../../common/typography"
import { MDXRenderer } from "../mdx-renderer"
import { SchemaRenderer } from "../schema/schema-renderer"

export type EventRendererProps = {
  event: ApiEvent
}

const EventRenderer: React.FC<EventRendererProps> = ({ event }) => {
  const { ref: intersectionRef, inView } = useInView({ threshold: 1 })
  const { setFocusedEvent } = useFocusedEvent()

  useEffect(() => {
    if (!inView) {
      return
    }

    window.history.replaceState({}, "", `${EVENTS_PAGE_PATH}#${event.name}`)
    setFocusedEvent(event.name)
  }, [inView, setFocusedEvent, event.name])

  const schema = JSON.parse(event.schemaData) as OpenAPIV3.SchemaObject
  const examplePayload = OpenApiUtils.generateExampleFromSchema(schema, false, false)

  return (
    <div className="flex flex-col md:flex-row gap-8 xl:gap-16 min-h-screen">
      <div className="flex flex-col gap-y-4 md:w-3/5">
        <div className="flex flex-col items-start" ref={intersectionRef}>
          <HeadingThree anchor={event.name}>{event.name}</HeadingThree>

          {schema.description != null && <MDXRenderer>{schema.description}</MDXRenderer>}
        </div>

        <div className="flex flex-col">
          <HeadingFour>Payload schema</HeadingFour>

          <div className="flex flex-col gap-y-4">
            <SchemaRenderer schema={schema} />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-y-8 md:w-2/5">
        <CodeSampleBlock title="Payload" contentType="application/json" code={JSON.stringify(examplePayload, null, 2)} language="json" />
      </div>
    </div>
  )
}

export default EventRenderer
