import React, { useEffect } from "react"
import { PageProps } from "gatsby"

import { MDXProvider } from "../../components/mdx/mdx-provider"
import PageMetadata from "../../components/page/page-metadata"
import DocumentationLayout from "../../components/documentation/documentation-layout"
import { HeadingOne } from "../../components/common/typography"
import { useApiEvents } from "../../hooks/api-events"
import EventRenderer from "../../components/api-reference/event/event-renderer"
import { useFocusedEvent } from "../../hooks/focused-event"

const ApiEventsPage: React.FC<PageProps> = ({ location }) => {
  const events = useApiEvents()
  const { setFocusedEvent } = useFocusedEvent()

  useEffect(() => {
    return () => {
      setFocusedEvent(undefined)
    }
  }, [setFocusedEvent])

  if (events.length === 0) {
    return null
  }

  const eventItems = events.map((event, index) => (
    <React.Fragment key={index}>
      <EventRenderer event={event} />
      {index < events.length - 1 && <hr className="border-neutral-200 mt-5 mb-9" />}
    </React.Fragment>
  ))

  return (
    <div className="mx-6 md:mx-12 xl:mx-20">
      <MDXProvider>
        <PageMetadata title="Events" />
        <DocumentationLayout path={location.pathname}>
          <HeadingOne unanchored>Events</HeadingOne>

          <div className="mt-8">{eventItems}</div>
        </DocumentationLayout>
      </MDXProvider>
    </div>
  )
}

export default ApiEventsPage
